@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Montserrat";
    src: url("../public/fonts/Montserrat-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("../public/fonts/Montserrat-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("../public/fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("../public/fonts/Montserrat-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-family: Montserrat, system-ui, sans-serif;
  }
}
